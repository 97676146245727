import { default as indexJRSATinaTEMeta } from "/home/runner/work/vue3-saas/vue3-saas/pages/analytics/dashboard/index.vue?macro=true";
import { default as indexy5rcGjKmmHMeta } from "/home/runner/work/vue3-saas/vue3-saas/pages/analytics/settings/index.vue?macro=true";
import { default as settings6f7ussHdXSMeta } from "/home/runner/work/vue3-saas/vue3-saas/pages/auth/settings.vue?macro=true";
import { default as indexvpJADjRsrIMeta } from "/home/runner/work/vue3-saas/vue3-saas/pages/index.vue?macro=true";
import { default as CheckoutYlayH5TNXtMeta } from "/home/runner/work/vue3-saas/vue3-saas/pages/integrations/Checkout.vue?macro=true";
import { default as HubmujDgmmynxMeta } from "/home/runner/work/vue3-saas/vue3-saas/pages/integrations/Hub.vue?macro=true";
import { default as _91id_93t8pzFtL1XJMeta } from "/home/runner/work/vue3-saas/vue3-saas/pages/integrations/shop/[id].vue?macro=true";
import { default as index4KIgxPV1xrMeta } from "/home/runner/work/vue3-saas/vue3-saas/pages/integrations/shop/index.vue?macro=true";
import { default as ListmNNOAyhd43Meta } from "/home/runner/work/vue3-saas/vue3-saas/pages/leads/companies/List.vue?macro=true";
import { default as indexG3jQ6bOA4dMeta } from "/home/runner/work/vue3-saas/vue3-saas/pages/leads/dashboard/index.vue?macro=true";
import { default as indexvJ125zvOunMeta } from "/home/runner/work/vue3-saas/vue3-saas/pages/leads/settings/index.vue?macro=true";
import { default as indexpCJmk9DSpeMeta } from "/home/runner/work/vue3-saas/vue3-saas/pages/workspace/settings/index.vue?macro=true";
export default [
  {
    name: indexJRSATinaTEMeta?.name ?? "analytics-dashboard",
    path: indexJRSATinaTEMeta?.path ?? "/analytics/dashboard",
    meta: indexJRSATinaTEMeta || {},
    alias: indexJRSATinaTEMeta?.alias || [],
    redirect: indexJRSATinaTEMeta?.redirect,
    component: () => import("/home/runner/work/vue3-saas/vue3-saas/pages/analytics/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexy5rcGjKmmHMeta?.name ?? "analytics-settings",
    path: indexy5rcGjKmmHMeta?.path ?? "/analytics/settings",
    meta: indexy5rcGjKmmHMeta || {},
    alias: indexy5rcGjKmmHMeta?.alias || [],
    redirect: indexy5rcGjKmmHMeta?.redirect,
    component: () => import("/home/runner/work/vue3-saas/vue3-saas/pages/analytics/settings/index.vue").then(m => m.default || m)
  },
  {
    name: settings6f7ussHdXSMeta?.name ?? "auth-settings",
    path: settings6f7ussHdXSMeta?.path ?? "/auth/settings",
    meta: settings6f7ussHdXSMeta || {},
    alias: settings6f7ussHdXSMeta?.alias || [],
    redirect: settings6f7ussHdXSMeta?.redirect,
    component: () => import("/home/runner/work/vue3-saas/vue3-saas/pages/auth/settings.vue").then(m => m.default || m)
  },
  {
    name: indexvpJADjRsrIMeta?.name ?? "index",
    path: indexvpJADjRsrIMeta?.path ?? "/",
    meta: indexvpJADjRsrIMeta || {},
    alias: indexvpJADjRsrIMeta?.alias || [],
    redirect: indexvpJADjRsrIMeta?.redirect,
    component: () => import("/home/runner/work/vue3-saas/vue3-saas/pages/index.vue").then(m => m.default || m)
  },
  {
    name: CheckoutYlayH5TNXtMeta?.name ?? "integrations-Checkout",
    path: CheckoutYlayH5TNXtMeta?.path ?? "/integrations/Checkout",
    meta: CheckoutYlayH5TNXtMeta || {},
    alias: CheckoutYlayH5TNXtMeta?.alias || [],
    redirect: CheckoutYlayH5TNXtMeta?.redirect,
    component: () => import("/home/runner/work/vue3-saas/vue3-saas/pages/integrations/Checkout.vue").then(m => m.default || m)
  },
  {
    name: HubmujDgmmynxMeta?.name ?? "integrations-Hub",
    path: HubmujDgmmynxMeta?.path ?? "/integrations/Hub",
    meta: HubmujDgmmynxMeta || {},
    alias: HubmujDgmmynxMeta?.alias || [],
    redirect: HubmujDgmmynxMeta?.redirect,
    component: () => import("/home/runner/work/vue3-saas/vue3-saas/pages/integrations/Hub.vue").then(m => m.default || m)
  },
  {
    name: _91id_93t8pzFtL1XJMeta?.name ?? "integrations-shop-id",
    path: _91id_93t8pzFtL1XJMeta?.path ?? "/integrations/shop/:id()",
    meta: _91id_93t8pzFtL1XJMeta || {},
    alias: _91id_93t8pzFtL1XJMeta?.alias || [],
    redirect: _91id_93t8pzFtL1XJMeta?.redirect,
    component: () => import("/home/runner/work/vue3-saas/vue3-saas/pages/integrations/shop/[id].vue").then(m => m.default || m)
  },
  {
    name: index4KIgxPV1xrMeta?.name ?? "integrations-shop",
    path: index4KIgxPV1xrMeta?.path ?? "/integrations/shop",
    meta: index4KIgxPV1xrMeta || {},
    alias: index4KIgxPV1xrMeta?.alias || [],
    redirect: index4KIgxPV1xrMeta?.redirect,
    component: () => import("/home/runner/work/vue3-saas/vue3-saas/pages/integrations/shop/index.vue").then(m => m.default || m)
  },
  {
    name: ListmNNOAyhd43Meta?.name ?? "leads-companies-List",
    path: ListmNNOAyhd43Meta?.path ?? "/leads/companies/List",
    meta: ListmNNOAyhd43Meta || {},
    alias: ListmNNOAyhd43Meta?.alias || [],
    redirect: ListmNNOAyhd43Meta?.redirect,
    component: () => import("/home/runner/work/vue3-saas/vue3-saas/pages/leads/companies/List.vue").then(m => m.default || m)
  },
  {
    name: indexG3jQ6bOA4dMeta?.name ?? "leads-dashboard",
    path: indexG3jQ6bOA4dMeta?.path ?? "/leads/dashboard",
    meta: indexG3jQ6bOA4dMeta || {},
    alias: indexG3jQ6bOA4dMeta?.alias || [],
    redirect: indexG3jQ6bOA4dMeta?.redirect,
    component: () => import("/home/runner/work/vue3-saas/vue3-saas/pages/leads/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexvJ125zvOunMeta?.name ?? "leads-settings",
    path: indexvJ125zvOunMeta?.path ?? "/leads/settings",
    meta: indexvJ125zvOunMeta || {},
    alias: indexvJ125zvOunMeta?.alias || [],
    redirect: indexvJ125zvOunMeta?.redirect,
    component: () => import("/home/runner/work/vue3-saas/vue3-saas/pages/leads/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexpCJmk9DSpeMeta?.name ?? "workspace-settings",
    path: indexpCJmk9DSpeMeta?.path ?? "/workspace/settings",
    meta: indexpCJmk9DSpeMeta || {},
    alias: indexpCJmk9DSpeMeta?.alias || [],
    redirect: indexpCJmk9DSpeMeta?.redirect,
    component: () => import("/home/runner/work/vue3-saas/vue3-saas/pages/workspace/settings/index.vue").then(m => m.default || m)
  }
]