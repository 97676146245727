// plugins/vue-echarts.client.ts
import {defineNuxtPlugin} from '#app';
import {use} from 'echarts/core';
import {
    CanvasRenderer
} from 'echarts/renderers';
import {
    BarChart,
    HeatmapChart,
    LineChart,
    PieChart
} from 'echarts/charts';
import {
    CalendarComponent,
    DatasetComponent,
    GridComponent,
    LegendComponent,
    ToolboxComponent,
    TooltipComponent,
    VisualMapComponent,
} from 'echarts/components';
import VueECharts from 'vue-echarts';

// Register the necessary components
use([
    BarChart,
    CalendarComponent,
    DatasetComponent,
    CanvasRenderer,
    GridComponent,
    HeatmapChart,
    LegendComponent,
    LineChart,
    PieChart,
    ToolboxComponent,
    TooltipComponent,
    VisualMapComponent,
]);

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component('VChart', VueECharts);
});
