import { createVuetify } from "vuetify";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import '@mdi/font/css/materialdesignicons.min.css'

//Mock Api data
// import "../_mockApis";

import { vMaska } from "maska";
import "vue3-carousel/dist/carousel.css";
import VueTablerIcons from "vue-tabler-icons";

import "@/assets/scss/style.scss";

// Table
import "vue3-easy-data-table/dist/style.css";
//i18
import { createI18n } from "vue-i18n";
import messages from "@/utils/locales/messages";
import {
  HUB_THEME,
  AQUA_THEME,
  PURPLE_THEME,
  GREEN_THEME,
  CYAN_THEME,
  ORANGE_THEME,
} from "@/theme/LightTheme";
import {
  DARK_HUB_THEME,
  DARK_AQUA_THEME,
  DARK_ORANGE_THEME,
  DARK_PURPLE_THEME,
  DARK_GREEN_THEME,
  DARK_CYAN_THEME,
} from "@/theme/DarkTheme";

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    ssr: true,
    theme: {
      defaultTheme: "BLUE_THEME",
      themes: {
        BLUE_THEME: HUB_THEME,
        AQUA_THEME,
        PURPLE_THEME,
        GREEN_THEME,
        CYAN_THEME,
        ORANGE_THEME,
        DARK_BLUE_THEME: DARK_HUB_THEME,
        DARK_AQUA_THEME,
        DARK_ORANGE_THEME,
        DARK_PURPLE_THEME,
        DARK_GREEN_THEME,
        DARK_CYAN_THEME,
      },
    },
    defaults: {
      VCard: {
        rounded: "md",
      },
      VTextField: {
        variant: "outlined",
        density: "comfortable",
        color: "primary",
      },
      VTextarea: {
        variant: "outlined",
        density: "comfortable",
        color: "primary",
      },
      VSelect: {
        variant: "outlined",
        density: "comfortable",
        color: "primary",
      },
      VListItem: {
        minHeight: "45px",
      },
      VTooltip: {
        location: "top",
      },
    },
  });

  const i18n = createI18n({
    locale: "en",
    messages: messages,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
  });
  nuxtApp.vueApp.use(vuetify);
  // nuxtApp.vueApp.component("EasyDataTable", Vue3EasyDataTable);
  nuxtApp.vueApp.use(VueTablerIcons)
  nuxtApp.vueApp.use(PerfectScrollbar);
  nuxtApp.vueApp.directive("maska", vMaska);
  nuxtApp.vueApp.use(i18n);
});
