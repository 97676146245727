import unhead_KgADcZ0jPj from "/home/runner/work/vue3-saas/vue3-saas/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/vue3-saas/vue3-saas/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/home/runner/work/vue3-saas/vue3-saas/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/home/runner/work/vue3-saas/vue3-saas/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/vue3-saas/vue3-saas/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/vue3-saas/vue3-saas/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/vue3-saas/vue3-saas/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/vue3-saas/vue3-saas/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8SbxDRbG6Y from "/home/runner/work/vue3-saas/vue3-saas/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_eTVJQYlCmx from "/home/runner/work/vue3-saas/vue3-saas/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_AOuQ1DYzjk from "/home/runner/work/vue3-saas/vue3-saas/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import pwa_client_Eorgdr12jA from "/home/runner/work/vue3-saas/vue3-saas/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/vue3-saas/vue3-saas/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/home/runner/work/vue3-saas/vue3-saas/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import error_handler_kEP5FliEXj from "/home/runner/work/vue3-saas/vue3-saas/plugins/error-handler.ts";
import markdownit_fsFV40uZ9C from "/home/runner/work/vue3-saas/vue3-saas/plugins/markdownit.ts";
import matomo_client_Ed2EqINkhd from "/home/runner/work/vue3-saas/vue3-saas/plugins/matomo.client.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/vue3-saas/vue3-saas/plugins/sentry.client.ts";
import vue_echarts_client_XQo2JYl8xu from "/home/runner/work/vue3-saas/vue3-saas/plugins/vue-echarts.client.ts";
import vuetify_7h9QAQEssH from "/home/runner/work/vue3-saas/vue3-saas/plugins/vuetify.ts";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_8SbxDRbG6Y,
  plugin_eTVJQYlCmx,
  plugin_AOuQ1DYzjk,
  pwa_client_Eorgdr12jA,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  error_handler_kEP5FliEXj,
  markdownit_fsFV40uZ9C,
  matomo_client_Ed2EqINkhd,
  sentry_client_shVUlIjFLk,
  vue_echarts_client_XQo2JYl8xu,
  vuetify_7h9QAQEssH
]